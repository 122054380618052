import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Img from 'gatsby-image'
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import '../assets/style.category.css'

const QUERY = graphql`
  query {
    products: allContentfulProduct(filter: {showOnHome: {eq: true}}) {
      edges {
        node {
          product
          slug
          images {
            description
            fluid(maxWidth: 480, maxHeight: 320) {
              ...GatsbyContentfulFluid_withWebp
            }
      		}
      	}
    	}
    }
    parallax: allFile(filter: {relativePath: {eq: "madeira.png"}}) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Index = () => {
  const { products, parallax } = useStaticQuery(QUERY)

  return (
    <div>
      <BackgroundImage
        Tag="section"
        className="parallax"
        fluid={parallax.edges[0].node.childImageSharp.fluid}
        backgroundColor={`#191410`}
      >
        <div className='hero-text text-white text-center min-h-screen'>
          <h3 className='px-10'>O MELHOR NA LINHA DE MÓVEIS, LAZER E DECORAÇÃO</h3>
          <Link to='/produtos'><h6 className='pt-10 md:pt-16'>CLIQUE AQUI E CONHEÇA NOSSOS PRODUTOS</h6></Link>
        </div>

        {/* <div className='banner-scroll'>
          <span className='scroll-down'></span>
          scroll
        </div> */}
      </BackgroundImage>

      <Layout ptop={false}>
        <SEO />

        <h1 className='invisible'>Requinte Básico</h1>

        <section className='vitrine'>
          <p className='text-center sm:flex mx-auto sm:ml-2 w-4/5 lg:w-2/3 robika text-base sm:text-sm lg:text-lg xl:text-xl underlin uppercase tracking-wide mb-6 md:mt-10 font-bold'>
            Produtos em destaque
          </p>
          <div className='container mx-auto flex items-center flex-wrap pt-2 pb-12'>
            {
              products.edges.map( product => {
                return (
                  <div key={product.node.slug} className='w-full md:w-1/3 xl:w-1/4 p-2 flex flex-col'>
                    <div className='blur bg-white md:hover:shadow-2xl md:hover:grow px-2 pt-2 pb-4 md:hover:bg-gray-400'>
                    <Link className='absolute bg-transparent text-white shadow-lg border-2 border-solid font-bold px-2 hidden-btn' to={'/produtos/'+product.node.slug}>VER DETALHES</Link>
                      <Link to={'/produtos/'+product.node.slug}>
                        <Img className='shadow bg-white' fluid={product.node.images[0].fluid} alt={product.node.images[0].description}/>
                        <div className='pt-3 flex items-center'>
                          <p className='pl-4 font-bold'>{product.node.product}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </section>
        <section className='flex flex-wrap justify-center'>
          <div className='text-center order-1 pt-1 pb-4 m-2'>
            <Link className='bg-transparent hover:bg-gray-700 text-gray-900 font-semibold hover:text-white py-2 px-4 border border-gray-700 hover:border-transparent font-bold' to='/produtos'>VER MAIS PRODUTOS</Link>
          </div>
          <div className='text-center order-2 pt-1 pb-4 m-2'>
            <Link className='bg-transparent hover:bg-gray-700 text-gray-900 font-semibold hover:text-white py-2 border border-gray-700 hover:border-transparent font-bold' to='/empresa' style={{paddingRight: '27px', paddingLeft: '27px'}}>SOBRE A EMPRESA</Link>
          </div>
          <div className='text-center order-3 pt-1 pb-4 m-2'>
            <Link className='bg-transparent hover:bg-gray-700 text-gray-900 font-semibold hover:text-white py-2 border border-gray-700 hover:border-transparent font-bold' to='/contato' style={{paddingRight: '14px', paddingLeft: '14px'}}>ENTRAR EM CONTATO</Link>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Index
